/**  =====================
    33. Slider css start
========================== **/
/* nav */
.tns-controls {
  text-align: center;
  margin-bottom: 10px;

  [aria-controls] {
    font-size: 15px;
    margin: 0 5px;
    padding: 0 1em;
    height: 2.5em;
    color: #fff;
    background: var(--bs-primary);
    border-radius: 3px;
    border: 0;
  }
}

[data-action] {
  display: block;
  margin: 10px auto;
  font-size: 17px;
  min-width: 3em;
  text-align: center;
  background: transparent;
  border: 0;
}

.tns-controls [disabled] {
  color: #999999;
  background: #b3b3b3;
  cursor: not-allowed !important;
}

.tns-nav {
  text-align: center;
  margin: 10px 0;

  > {
    [aria-controls] {
      width: 9px;
      height: 9px;
      padding: 0;
      margin: 0 5px;
      border-radius: 50%;
      background: #ddd;
      border: 0;
    }

    .tns-nav-active {
      background: #999;
    }
  }
}

.thumbnails {
  margin: 20px 0;
  text-align: center;
  @include media-breakpoint-down(sm) {
    padding: 0;
  }

  li {
    display: inline-block;
    cursor: pointer;
    border: 5px solid #fff;
  }

  .tns-nav-active {
    background: none;
    border-color: var(--bs-primary);
  }

  img {
    height: auto;
    width: 46px;
  }
}

.customize-tools {
  position: relative;
}

.controls {
  text-align: center;

  li {
    display: block;
    position: absolute;
    top: 50%;
    height: 60px;
    line-height: 60px;
    margin-top: -30px;
    padding: 0 15px;
    cursor: pointer;
    font-size: 30px;
    transition: background 0.3s;
    @include media-breakpoint-down(sm) {
      font-size: 20px;
      line-height: 1;
      height: unset;
    }
  }

  .prev {
    left: 0;
  }

  .next {
    right: 0;
  }

  li:hover {
    background: #f2f2f2;
  }
}
/**  =====================
    33. Slider css end
========================== **/