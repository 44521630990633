// ==========================
//       Price css start
// ==========================

.price-card {
  .price-head {
    border-radius: var(--bs-border-radius) var(--bs-border-radius) 60px 60px;
    padding: 20px;
    text-align: center;
    margin: 15px 15px 30px;
    padding-bottom: 30px;
    position: relative;
  }
  .price-icon{
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 35px;
  }
  .price-price {
    font-size: 40px;
    font-weight: 700;
    line-height: 1;

    span {
      margin-top: 8px;
      font-weight: 400;
      font-size: 0.875rem;
      opacity: .8;
    }
  }

  .product-list {
    li {
      padding: 4px 0 4px 25px;
      position: relative;
      .ph-duotone{
        position: absolute;
        top: 8px;
        left: 0;
        color: var(--bs-primary);
      }
      &:not(.enable) {
        opacity: 0.5;
        text-decoration: line-through;
      }
    }
  }

  &.price-popular {
    .price-head {
      background: rgba(var(--bs-primary-rgb),.1);
      margin-bottom: 16px;
    }
  }
}

.product-check-list {
  text-align: left;

  li {
    padding: 8px 0 8px 25px;
    position: relative;
    opacity: 0.5;

    &::before {
      content: "\ea5e";
      font-family: tabler-icons !important;
      position: absolute;
      left: 0;
      top: 8px;
      opacity: 0.5;
    }

    &.enable {
      opacity: 1;

      &::before {
        opacity: 1;
        color: $success;
      }
    }
  }
}

.price-check {
  overflow: hidden;
  position: relative;
  .form-check-label{
    &::after{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  .form-check-input,
  .form-check-label .row{
    position: relative;
    z-index: 8;
  }
  .form-check {
    .form-check-input {
      &:checked{
        + .form-check-label{
          &::after{
            background: rgba(var(--bs-primary-rgb),.1);
          }
        }
      }
    }
  }
}

.plan-switch {
  .form-check-input {
    border: 1px solid var(--pc-sidebar-submenu-border-color);
  }
}

// Price css end